import React, { useContext } from "react"
import {
  Container,
  Box,
  useMediaQuery,
  Grid,
  FormControl,
  TextField,
  Typography,
} from "@material-ui/core"
import Button from "../../Button"
import * as Yup from "yup"
import { useTheme } from "@material-ui/styles"
import { useStyles } from "./style"
import Paper from "../../Paper"
import { Formik, Form, ErrorMessage } from "formik"
import FormErrors from "../../FormErrors"
import loanContext from "../../../context/loan/loanContext"

const ContactLayout = () => {
  const { isSubmitting, submitContact } = useContext(loanContext)

  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))

  const initialValues = {
    name: "",
    company: "",
    phoneNumber: "",
    email: "",
    message: "",
  }

  const validationSchema = Yup.object({
    name: Yup.string()
    .test({
      name: 'validator-custom-name',
      test: function(value) {
        return /\d/.test(value) ?
        this.createError({
          message: "Invalid name",
          path: 'name'
        }) : true
      }
    })
    .min(3, 'Not less than 3 characters')
    .required("Name is empty"),
    // company: Yup.string().required("Company is empty"),
    phoneNumber: Yup.string()
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Enter a valid phone number"
      )
      .min(9, "Phone Number must not be less than 9 characters")
      .required("Phone Number is empty"),
    email: Yup.string()
      .email("Invalid email format!")
      .required("Email is empty"),
    message: Yup.string().required("Message is empty"),
  })

  const onSubmit = (values, resetForm) => {
    submitContact(values, resetForm)
  }

  return (
    <Box
      id="contact"
      position="relative"
      paddingTop={"9rem"}
      paddingBottom="6.25rem"
      className={classes.root}
    >
      <Container maxWidth={"lg"}>
        <Box>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={5}>
              <Box marginTop="7rem">
                <Typography
                  variant="h1"
                  className={classes.headerTitle}
                  gutterBottom
                >
                  Get in touch with the Educollect Team
                </Typography>
                <Typography variant="body1" className={classes.headerSubtitle}>
                  If you have any more questions or are not sure which loan plan
                  is right for you, contact our team and let’s schedule a call.
                </Typography>
                <Box marginTop="5rem">
                  <Typography
                    variant="h4"
                    className={classes.headerTitle}
                    gutterBottom
                  >
                    Contact Us
                  </Typography>
                  <Typography variant="body1">
                    <strong>Email: </strong>
                    <a
                      href="mailto:support@educollectfinance.com"
                      style={{ textDecoration: "none", color: "#000000" }}
                    >
                      support@educollectfinance.com
                    </a>
                  </Typography>
                  <Typography variant="body1">
                    <strong>Phone: </strong>
                    +2348143906442
                  </Typography>
                  {/* <Typography variant="body1">
                    <strong>Whatsapp: </strong>
                    <a
                      href="https://api.whatsapp.com/send?phone=2348143906442"
                      target="_blank"
                      style={{ textDecoration: "none", color: "#000000" }}
                    >
                      Connect
                    </a>
                  </Typography> */}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={7}>
              <Box marginTop="3rem" display="flex" justifyContent="flex-end">
                <Box height="100%" className={classes.form}>
                  <Paper
                    padding={isMobile ? "1rem 1.5rem" : "2rem 2.5rem 3rem"}
                    borderRadius="0"
                  >
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={(initialValues, { resetForm }) => {
                        onSubmit(initialValues, resetForm)
                      }}
                    >
                      {({ getFieldProps }) => (
                        <Form noValidate autoComplete="off">
                          <FormControl className={classes.formControl}>
                            <Typography className={classes.text}>
                              Full Name
                            </Typography>
                            <TextField
                              name="name"
                              id="name"
                              // placeholder="eg. Samuel Fabayo"
                              variant="outlined"
                              {...getFieldProps("name")}
                              size="small"
                            />
                            <ErrorMessage name="name" component={FormErrors} />
                          </FormControl>

                          <FormControl className={classes.formControl}>
                            <Typography className={classes.text}>
                              Company (Optional)
                            </Typography>
                            <TextField
                              name="company"
                              id="company"
                              // placeholder="Global Company"
                              {...getFieldProps("company")}
                              variant="outlined"
                              size="small"
                            />
                            <ErrorMessage
                              name="company"
                              component={FormErrors}
                            />
                          </FormControl>
                          <FormControl className={classes.formControl}>
                            <Typography className={classes.text}>
                              Phone Number
                            </Typography>
                            <TextField
                              name="PhoneNumber"
                              id="phoneNumber"
                              // placeholder="08038567342"
                              {...getFieldProps("phoneNumber")}
                              variant="outlined"
                              size="small"
                            />
                            <ErrorMessage
                              name="phoneNumber"
                              component={FormErrors}
                            />
                          </FormControl>

                          <FormControl className={classes.formControl}>
                            <Typography className={classes.text}>
                              Work email
                            </Typography>
                            <TextField
                              name="email"
                              id="email"
                              // placeholder="your@email.com"
                              {...getFieldProps("email")}
                              variant="outlined"
                              size="small"
                            />
                            <ErrorMessage name="email" component={FormErrors} />
                          </FormControl>

                          <FormControl className={classes.formControl}>
                            <Typography className={classes.text}>
                              Your Message
                            </Typography>
                            <TextField
                              name="message"
                              id="message"
                              fullWidth
                              multiline
                              // placeholder="Enter message here"
                              rows={4}
                              {...getFieldProps("message")}
                              variant="outlined"
                              size="small"
                            />
                            <ErrorMessage
                              name="message"
                              component={FormErrors}
                            />
                          </FormControl>

                          <FormControl className={classes.submit}>
                            <Button
                              variant="contained"
                              color="primary"
                              disabled={isSubmitting}
                              loading={isSubmitting}
                              type="submit"
                            >
                              Submit
                            </Button>
                          </FormControl>
                        </Form>
                      )}
                    </Formik>
                  </Paper>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default ContactLayout
