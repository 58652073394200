import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: '#F2F8FF',
    },
    whatsapp: {
        border:'2px solid green',
        position: 'fixed',
        bottom: '10%',
        right: '10%',
    },
    form: {
        width: "65%",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
          },
    },
    text: {
        fontSize: "14px"
    },
   formControl: {
       width: "100%",
       marginTop: "1rem"
   },
   submit: {
    margin: theme.spacing(4, 0, 0, 0),
    minWidth: `100%`,
  },
}));

export { useStyles }